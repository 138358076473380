import * as React from 'react';

import styled from '@emotion/styled';
import { StyledRankCell, StyledRankCellHeader, StyledTickerNameCell, StyleLastUpdatedBlock, TickerSymbolAndName } from './TrendsTable';
import { abbreviateNumber } from '../utils/numbers';
import Tables from './Tables';

const StyledGammaCell = styled(Tables.Cell)`
  font-size: 0.9em;
`;

export function GammaSection({ gammas }) {
  return (
    <>
      <h1>Potential Gamma Squeezes</h1>
      <p>
        Gamma squeezes cause an increase in the price of a stock based on investors buying many call options that are or become ITM
        (In-The-Money) before expiry and option sellers needing to hedge their trades on the underlying stocks. Greatly accentuated for
        stocks with low float. These are the potential upcoming gamma squeezes.
      </p>
      <Tables.Table>
        <Tables.Head>
          <tr>
            <StyledRankCellHeader>#</StyledRankCellHeader>
            <Tables.HeadCell>Symbol (Name)</Tables.HeadCell>
            <Tables.HeadCell title="When the contracts expire">Expiring</Tables.HeadCell>
            <Tables.HeadCell title="The total number of contracts exprining">Open Interest</Tables.HeadCell>
            <Tables.HeadCell title="The number of contracts that are ITM (In-The-Money)">ITM</Tables.HeadCell>
            <Tables.HeadCell title="The number of shares the contracts are worth (Open Interest * 100)">Shares</Tables.HeadCell>
            <Tables.HeadCell title="The total shares available to trade">Float</Tables.HeadCell>
            <Tables.HeadCell title="The number of ITM contracts shares as a percent of the float">% of Float</Tables.HeadCell>
          </tr>
        </Tables.Head>
        <Tables.Body>
          {gammas.squeezes.map((t, index) => (
            <tr key={`${t.symbol}_${t.expiryDate}`}>
              <StyledRankCell>{index + 1}.</StyledRankCell>
              <StyledTickerNameCell title={t.description}>
                <TickerSymbolAndName ticker={t} />
              </StyledTickerNameCell>
              <StyledGammaCell>{t.expiryDate}</StyledGammaCell>
              <StyledGammaCell>{t.totalContractsExpiringFormatted}</StyledGammaCell>
              <StyledGammaCell>{t.totalContractsInTheMoneyFormatted}</StyledGammaCell>
              <StyledGammaCell>{abbreviateNumber(t.totalContractsInTheMoney * 100)}</StyledGammaCell>
              <StyledGammaCell>{t.float}</StyledGammaCell>
              <StyledGammaCell>{t.percentOfFloatFormatted}</StyledGammaCell>
            </tr>
          ))}
        </Tables.Body>
      </Tables.Table>
      <StyleLastUpdatedBlock>Last Updated: {gammas.lastUpdatedEst}</StyleLastUpdatedBlock>
    </>
  );
}
